import { PhoneIcon } from 'assets/phone-icon';
import { useMixpanelTrack } from 'context/mix-pannel-context';
import { ManagementView } from 'policy-management/policy-management-scene';
import React from 'react';
import { HighlightButton } from 'rootstrap/components/button/styles';
import { globalStyles } from 'rootstrap/global-styles';
import { devices } from 'rootstrap/global-styles/devices';
import { useSync } from 'shared/hooks/promise';
import {
  getColor,
  getDisplayOptionalSections,
  getLink,
  getWording,
  ProductModuleDefinitionEmbeddedConfig,
} from 'site-config';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';

export const CreateClaim = () => {
  const { siteConfig } = useSiteConfigContext();
  const { actionCompletedTrack } = useMixpanelTrack();

  const openClaimLink = getLink({ link: siteConfig?.management?.claim.links.openClaim });
  const contactNumberWording = getWording({ wording: siteConfig?.management?.claim.wording.contactNumber });
  const callToAction = getWording({ wording: siteConfig?.management?.claim.wording.callToAction });
  const displayCallToAction = getDisplayOptionalSections({
    displayOptionalSection: siteConfig?.management?.claim.displayOptionalSections.callToAction,
  });
  const displayContactNumber = getDisplayOptionalSections({
    displayOptionalSection: siteConfig?.management?.claim.displayOptionalSections.contactNumber,
  });

  return (
    <ContentContainer siteConfig={siteConfig}>
      <p id='claim-description' className='large-text'>
        Want to make a claim?
      </p>
      {displayCallToAction && (
        <HighlightButton
          fullWidth
          siteConfig={siteConfig}
          onClick={() => {
            actionCompletedTrack({ action: ManagementView.Claims });
            window.parent.location.href = openClaimLink;
          }}
        >
          {callToAction}
        </HighlightButton>
      )}
      {displayContactNumber && (
        <div className='contact-info'>
          <p>
            <PhoneIcon className='phone-icon' fill={getColor({ siteConfig, color: 'highlight' })} />
            or call us on <a href={`tel:${contactNumberWording}`}>{contactNumberWording}</a>
          </p>
        </div>
      )}
    </ContentContainer>
  );
};

const ContentContainer = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  margin-top: ${globalStyles.spacing.default};

  @media ${devices.tablet} {
    padding-left: 20px;
    padding-right: 20px;
  }

  .large-text {
    font-size: ${globalStyles.fontSize.title};
    margin-bottom: ${globalStyles.spacing.lg};
  }

  .btn {
    margin-bottom: ${globalStyles.spacing.default};
  }

  .contact-info {
    a {
      color: ${({ siteConfig }) => siteConfig?.styles.colors.highlight};
    }

    svg {
      margin-right: 12px;
    }
  }
`;
