import { getPolicyAlterationHooks } from 'alteration-hooks/actions/get-product-module-definition-alteration-hooks';
import { AlterationPackage } from 'alteration-hooks/domain/alteration-package';
import { ProductModuleDefinitionAlterationHook } from 'alteration-hooks/domain/product-module-definition-alteration-hooks';
import { EmbedFlowType } from 'App';
import { BeneficiarySummary, BeneficiarySummaryType } from 'beneficiaries/views/beneficiary-summary';
import { getPolicyById } from 'policies/actions/get-policy-by-id';
import { Policy } from 'policies/domain/policy';
import { beneficiariesDescriptionWording } from 'policy-issuing/personal-details/utils/beneficiaries-description-wording';
import { InsurerDisclaimerFooter } from 'policy-issuing/policy-issuing-scene';
import { getPolicyholder } from 'policyholder/actions/get-policyholder';
import { Policyholder } from 'policyholder/domain/policyholder';
import { ProductModule } from 'product-modules/domain/product-module';
import React, { SetStateAction, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DefaultStepKeys, SteppedFullscreenModal } from 'rootstrap/components/modal/stepped-fullscreen-modal';
import { SpacerLeft, SpacerRight } from 'rootstrap/components/modal/styles/form-overlay-styles';
import { FullScreenModalFooterOverlay } from 'rootstrap/components/modal/styles/fullscreen-modal-styles';
import { Content, FlexContainer, MainLayout, MainLayoutContent } from 'rootstrap/components/structure';
import { ApiError, usePolicyManagementUrl } from 'shared/api';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { usePromise, useSync } from 'shared/hooks/promise';
import { useQueryParams } from 'shared/hooks/url';
import { useSiteConfigContext } from 'style-context';
import { CreateAlterationPackageScene } from './alteration-hooks/create-alteration-package-scene';
import { AlterationStepActionBar } from './alteration-hooks/views/alteration-package-step-action-bar';
import { CancelPolicy } from './cancel-policy/cancel-policy';
import { CreateClaim } from './claims/create-claim';
import { PaymentMethodScene } from './payment-details/payment-details-view';
import { PolicyViewScene } from './policy-details/policy-view-scene';
import { PolicyDetailsScene } from './policy-details/views/policy-details-scene';
import { MixpanelEventNames, MixpanelStepNames, useMixpanel, useMixpanelTrack } from 'context/mix-pannel-context';

export enum ManagementStepKeys {
  PolicyView = 0,
  Beneficiaries = 1,
  Claims = 2,
  Cancel = 3,
  PolicyDetails = 4,
  Alterations = 5,
  PaymentDetails = 6,
}

export enum ManagementView {
  PolicyView = 'policy_view',
  Beneficiaries = 'beneficiaries',
  Claims = 'claims',
  Cancel = 'cancel',
  PolicyDetails = 'policy_details',
  Alterations = 'alterations',
  PaymentDetails = 'payment_details',
}

const viewToStepKey = (view: ManagementView | undefined) => {
  const views = {
    [ManagementView.PolicyView]: ManagementStepKeys.PolicyView,
    [ManagementView.Beneficiaries]: ManagementStepKeys.Beneficiaries,
    [ManagementView.Claims]: ManagementStepKeys.Claims,
    [ManagementView.Cancel]: ManagementStepKeys.Cancel,
    [ManagementView.PolicyDetails]: ManagementStepKeys.PolicyDetails,
    [ManagementView.Alterations]: ManagementStepKeys.Alterations,
    [ManagementView.PaymentDetails]: ManagementStepKeys.PaymentDetails,
  };

  return view ? views[view] : ManagementStepKeys.PolicyView;
};

export interface PolicyManagementSceneBaseParams {
  isLoading: boolean;
  error: ApiError | undefined;
}

export interface SetManagementViewParams {
  view: ManagementView;
  draft_collection_module?: boolean;
  alteration_hook_name?: string;
  alteration_hook_key?: string;
  query?: { key: string; value: string }[];
}

export const PolicyManagementScene = (props: { productModule: ProductModule }) => {
  const history = useHistory();
  const { productModule } = props;
  const { siteConfig } = useSiteConfigContext();
  const onNextClickedRef = useRef<any>();
  const { queryParams } =
    useQueryParams<{ view: ManagementView; alteration_hook_name?: string; draft_collection_module?: boolean }>();
  const [view, setView] = useState<SetManagementViewParams | undefined>({
    view: queryParams.view,
    query: [
      ...(queryParams.alteration_hook_key
        ? [{ key: 'alteration_hook_key', value: queryParams.alteration_hook_key }]
        : []),
      ...(queryParams.alteration_hook_name
        ? [{ key: 'alteration_hook_name', value: queryParams.alteration_hook_name }]
        : []),
    ],
  });
  const [policyholder, setPolicyholder] = useState<Policyholder | undefined>();
  const [policy, setPolicy] = useState<Policy | undefined>();
  const { embedParams } = useEmbedParamsContext();
  const mixpanel = useMixpanel();
  const { actionCompletedTrack, actionExitedTrack } = useMixpanelTrack();

  const { auth, environment, organizationId } = embedParams;
  const policyId = queryParams.policy_id;
  const alterationHookName = queryParams.alteration_hook_name;
  const header = siteConfig?.header;

  const rootSchemaFormAlterationPackageRef = useRef<any>();

  // Beneficiaries
  const [isBeneficiariesValid, setIsBeneficiariesValid] = useState<boolean>(true);
  const [isBeneficiariesLoading, setIsBeneficiariesLoading] = useState<boolean>(false);

  // Alteration hooks
  const [alterationHooks, setAlterationHooks] = useState<ProductModuleDefinitionAlterationHook[]>([]);
  const [alterationPackages, setAlterationPackages] = useState<AlterationPackage[] | undefined>(undefined);

  const [createAlterationPackageError, setCreateAlterationPackageError] = useState<ApiError | undefined>(undefined);
  const [isCreatingAlterationPackage, setIsCreatingAlterationPackage] = useState<boolean>(false);

  useSync(() => {
    if (embedParams.productModuleKey) {
      mixpanel.track(MixpanelEventNames.SessionInitiated, view?.view || ManagementView.PolicyView);
    }
  }, [embedParams.productModuleKey]);

  // PolicyDetails
  const UpdateManagementUrl = (view: SetManagementViewParams) =>
    usePolicyManagementUrl({
      view: view.view,
      policyId,
      query: view.query,
    });

  // Button refs
  const beneficiaryFormSubmitButtonRef = useRef<any>();
  const {
    error,
    isLoading: isLoadingPolicy,
    execute,
  } = usePromise(async () => {
    const policyholder = await getPolicyholder({ organizationId, auth, environment });
    setPolicyholder(policyholder);

    const policy = await getPolicyById({ auth, environment, organizationId, policyId });
    setPolicy(policy);

    const alterationHooks = await getPolicyAlterationHooks({
      auth,
      environment,
      organizationId,
      policyId: policy.policyId,
    });
    setAlterationHooks(alterationHooks);
  }, [embedParams]);

  useMemo(() => {
    if (view) {
      history.push(UpdateManagementUrl(view));
    }
  }, [view]);

  const headingSplashSteps = !error ? [ManagementStepKeys.PolicyDetails, ManagementStepKeys.PolicyView] : [];
  const quotePackageDisplaySteps = !error ? [ManagementStepKeys.Alterations] : [];

  const beneficiaries = props.productModule?.productModuleDefinition?.settings.beneficiaries;
  const minimumBeneficiaries = beneficiaries?.min as number | undefined;
  const maximumBeneficiaries = beneficiaries?.max as number | undefined;
  const callToActionText = siteConfig?.payment.wording.callToAction || 'Pay now';

  return (
    <MainLayout>
      <MainLayoutContent>
        <Content>
          <FlexContainer>
            <div className='section' id='summary'>
              <SteppedFullscreenModal
                isLoadingBackButton={false}
                title={header?.wording.title || ''}
                headingSplashSteps={headingSplashSteps}
                quotePackageDisplaySteps={quotePackageDisplaySteps}
                disableNavbar
                productModule={productModule}
                error={error}
                premiumDisplay={undefined}
                onNextClickedRef={onNextClickedRef}
                isLoading={false}
                currentStepKey={viewToStepKey(view?.view)}
                onClose={() => undefined}
                firstStep={undefined}
                getSteps={(params: {
                  setCurrentStepKey: (key: SetStateAction<any | undefined>) => void;
                  setPriorStepKey: (key: SetStateAction<any | undefined>) => void;
                  getPriorStepKey: () => SetStateAction<any | undefined>;
                  onNextCompleted: (() => void | Promise<void>) | undefined;
                  onBackCompleted: (() => void | Promise<void>) | undefined;
                }) => [
                  {
                    key: ManagementStepKeys.PolicyView,
                    title: siteConfig?.management?.policyView.wording.title,
                    stepName: DefaultStepKeys.PolicyView,
                    description: siteConfig?.management?.policyView.wording.description,
                    continueButtonProps: {
                      isLoading: isLoadingPolicy,
                      disabled: false,
                      nextButtonText: '',
                      isNextButtonHidden: true,
                    },
                    secondaryButton: {
                      enabled: Boolean(siteConfig?.management?.policyView.links.exitRedirect),
                      onBackClicked: () => {
                        actionExitedTrack({ action: ManagementView.PolicyView });

                        const exitRedirectUrl = siteConfig?.management?.policyView?.links?.exitRedirect;
                        handleBackClick({ exitRedirectUrl, setView });
                      },
                      icon: undefined,
                    },
                    body: () => (
                      <PolicyViewScene
                        error={error}
                        policy={policy}
                        setView={(view) =>
                          setView({
                            view,
                          })
                        }
                        isLoading={isLoadingPolicy}
                        productModule={productModule}
                      />
                    ),
                    onNextClick: async () => {
                      beneficiaryFormSubmitButtonRef.current.click();
                    },
                  },
                  {
                    key: ManagementStepKeys.Beneficiaries,
                    title: siteConfig?.management?.beneficiaries?.wording.title,
                    stepName: DefaultStepKeys.ManageBeneficiaries,
                    description: beneficiariesDescriptionWording({ maximumBeneficiaries, minimumBeneficiaries }),
                    secondaryButton: {
                      enabled: true,
                      onBackClicked: () => {
                        actionExitedTrack({ action: ManagementView.Beneficiaries });

                        const exitRedirectUrl = siteConfig?.management?.beneficiaries?.links?.exitRedirect;
                        handleBackClick({ exitRedirectUrl, setView });
                      },
                      icon: undefined,
                    },
                    continueButtonProps: {
                      isLoading: isLoadingPolicy || isBeneficiariesLoading,
                      disabled: !isBeneficiariesValid,
                      nextButtonText: 'Update',
                    },
                    body: () => (
                      <BeneficiarySummary
                        type={BeneficiarySummaryType.Policy}
                        policyholder={policyholder}
                        error={error}
                        sectionIndices={[]}
                        setIsLoading={(isBeneficiariesLoading) => setIsBeneficiariesLoading(isBeneficiariesLoading)}
                        isLoading={isLoadingPolicy}
                        linkedEntity={policy}
                        productModule={productModule}
                        setLinkedEntity={setPolicy}
                        setIsValid={(isBeneficiariesValid) => setIsBeneficiariesValid(isBeneficiariesValid)}
                        beneficiarySummarySceneType={EmbedFlowType.PolicyManagement}
                        setStepProgress={(step) =>
                          setView({
                            view: ManagementView.PolicyView,
                          })
                        }
                        priorStepKey={params.getPriorStepKey()}
                        submitButtonRef={beneficiaryFormSubmitButtonRef}
                      />
                    ),
                    onNextClick: async () => {
                      beneficiaryFormSubmitButtonRef.current.click();
                      actionCompletedTrack({
                        action: ManagementView.Beneficiaries,
                        extraProperties: {
                          beneficiaries: (policy?.beneficiaries || []).map((b) => ({
                            dateOfBirth: b.dateOfBirth?.format('YYYY-MM-DD'),
                            gender: b.gender,
                            identificationCountry: b.id?.country,
                            identificationType: b.id?.type,
                            relationship: b.relationship,
                          })),
                        },
                      });
                    },
                  },
                  {
                    title: siteConfig?.management?.claim?.wording?.title,
                    stepName: DefaultStepKeys.ManageClaims,
                    description: siteConfig?.management?.claim?.wording?.description,
                    key: ManagementStepKeys.Claims,
                    onBackClick: () => undefined,
                    body: () => <CreateClaim />,
                    continueButtonProps: {
                      disabled: false,
                      isLoading: isLoadingPolicy,
                      isNextButtonHidden: true,
                    },
                    secondaryButton: {
                      enabled: true,
                      onBackClicked: () => {
                        actionExitedTrack({ action: ManagementView.Claims });
                        const exitRedirectUrl = siteConfig?.management?.claim?.links?.exitRedirect;
                        handleBackClick({ exitRedirectUrl, setView });
                      },
                      icon: undefined,
                    },
                    onNextClick: () => {},
                  },
                  {
                    title: siteConfig?.management?.policyDetails?.wording?.title,
                    description: siteConfig?.management?.policyDetails?.wording?.description,
                    stepName: DefaultStepKeys.PolicyDetails,
                    key: ManagementStepKeys.PolicyDetails,
                    onBackClick: () => undefined,
                    secondaryButton: {
                      enabled: true,
                      onBackClicked: () => {
                        actionExitedTrack({ action: ManagementView.PolicyDetails });
                        const exitRedirectUrl = siteConfig?.management?.policyDetails.links.exitRedirect || '';
                        handleBackClick({ exitRedirectUrl, setView });
                      },
                      icon: undefined,
                    },

                    body: () => (
                      <PolicyDetailsScene
                        error={error}
                        setView={(view) => setView(view)}
                        policyholder={policyholder}
                        policy={policy}
                        productModule={productModule}
                        alterationHooks={alterationHooks}
                        isLoading={isLoadingPolicy}
                      />
                    ),
                    continueButtonProps: {
                      disabled: false,
                      isLoading: isLoadingPolicy,
                      isNextButtonHidden: true,
                    },
                    onNextClick: () => {},
                  },
                  {
                    title: alterationHookName || '',
                    stepName: DefaultStepKeys.UpdatePolicy,
                    description: '',
                    key: ManagementStepKeys.Alterations,
                    secondaryButton: {
                      enabled: true,
                      icon: undefined,
                      onBackClicked: () => {
                        actionExitedTrack({ action: ManagementView.Alterations });
                        setView({
                          view: ManagementView.PolicyDetails,
                        });
                      },
                    },
                    onBackClick: () => undefined,
                    body: () => (
                      <CreateAlterationPackageScene
                        error={error}
                        rootSchemaFormAlterationPackageRef={rootSchemaFormAlterationPackageRef}
                        onNextCompleted={() => undefined}
                        alterationPackages={alterationPackages}
                        setAlterationPackages={(alterationPackages) => setAlterationPackages(alterationPackages)}
                        policy={policy}
                        alterationHooks={alterationHooks}
                        productModuleDefinition={productModule.productModuleDefinition}
                        isLoading={isLoadingPolicy}
                        prefillValues={undefined}
                        setIsCreatingAlterationPackage={(isCreatingAlterationPackage) =>
                          setIsCreatingAlterationPackage(isCreatingAlterationPackage)
                        }
                        setCreateAlterationPackageError={(createAlterationPackageError) =>
                          setCreateAlterationPackageError(createAlterationPackageError)
                        }
                      />
                    ),
                    continueButtonProps: {
                      disabled: false,
                      isLoading: isLoadingPolicy,
                      isNextButtonHidden: true,
                    },
                    onNextClick: () => {},
                  },
                  {
                    title: 'Cancel Policy',
                    stepName: DefaultStepKeys.CancelPolicy,
                    description: '',
                    key: ManagementStepKeys.Cancel,
                    secondaryButton: {
                      enabled: true,
                      icon: undefined,
                      onBackClicked: () => {
                        actionExitedTrack({ action: ManagementView.Cancel });
                        setView({
                          view: ManagementView.PolicyDetails,
                        });
                      },
                    },
                    onBackClick: () => undefined,
                    body: () => (
                      <CancelPolicy
                        policy={policy}
                        isLoadingPolicy={isLoadingPolicy}
                        setCurrentStepKey={params.setCurrentStepKey}
                        setPolicy={(policy) => setPolicy(policy)}
                      />
                    ),
                    continueButtonProps: {
                      disabled: false,
                      isLoading: isLoadingPolicy,
                      isNextButtonHidden: true,
                    },
                    onNextClick: () => {},
                  },
                  {
                    title: 'Your payment details',
                    stepName: DefaultStepKeys.ManagePaymentMethod,
                    description: '',
                    key: ManagementStepKeys.PaymentDetails,
                    secondaryButton: {
                      enabled: true,
                      icon: undefined,
                      onBackClicked: () => {
                        actionExitedTrack({ action: ManagementView.PaymentDetails });
                        setView({
                          view: ManagementView.PolicyView,
                        });
                      },
                    },
                    onBackClick: () => undefined,
                    body: () => (
                      <PaymentMethodScene
                        policy={policy}
                        setPolicy={setPolicy}
                        refetchPolicy={execute}
                        policyholder={policyholder}
                        productModule={productModule}
                      />
                    ),
                    continueButtonProps: {
                      disabled: false,
                      isLoading: isLoadingPolicy,
                      isNextButtonHidden: true,
                    },
                    onNextClick: () => {},
                  },
                ]}
                finalStepButtonContent={callToActionText}
                CustomActionBarContent={(step: any) => <></>}
                footer={() => (
                  <>
                    {view?.view === ManagementView.Alterations && (
                      <FullScreenModalFooterOverlay siteConfig={siteConfig}>
                        <SpacerLeft />
                        <div className='main'>
                          <AlterationStepActionBar
                            rootSchemaFormAlterationPackageRef={rootSchemaFormAlterationPackageRef}
                            alterationPackages={alterationPackages}
                            error={createAlterationPackageError}
                            isCreatingAlterationPackage={isCreatingAlterationPackage}
                            productModuleDefinition={productModule.productModuleDefinition}
                            setPolicy={(policy) => setPolicy(policy)}
                            policy={policy}
                            setView={(view) => {
                              setView({
                                view,
                              });
                            }}
                          />
                        </div>
                        <SpacerRight />
                      </FullScreenModalFooterOverlay>
                    )}
                    <InsurerDisclaimerFooter siteConfig={siteConfig} />
                  </>
                )}
              />
            </div>
          </FlexContainer>
        </Content>
      </MainLayoutContent>
    </MainLayout>
  );
};

const handleBackClick = (params: {
  exitRedirectUrl: string | undefined;
  setView: (params: { view: ManagementView }) => void;
}) => {
  const { exitRedirectUrl, setView } = params;

  if (exitRedirectUrl) {
    return (window.parent.location.href = exitRedirectUrl);
  }
  setView({ view: ManagementView.PolicyView });
};
