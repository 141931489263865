import { Application } from 'applications/domain/application';
import { Beneficiary } from 'beneficiaries/domain/beneficiary';
import { Input } from 'reactstrap';
import { Policyholder } from 'policyholder/domain/policyholder';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { LoadingLines, WrappedLoadingInputs } from 'rootstrap/components-old/loaders/loading-lines';
import { getSliderTooltipAlignment, StyledSlider } from 'rootstrap/components/slider/slider';
import { getColor, getFontFamily, getFontSize, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import { SuccessButton } from 'rootstrap/components/button/styles';
import { RadioButtonWrapper } from 'rootstrap/components/forms/new-fields/radio-button-field';
import { globalStyles, Spacing } from 'rootstrap/global-styles';
import {
  BeneficiaryAllocateButtonWrapperStyle,
  BeneficiaryCardTitleStyle,
  BeneficiaryCardTitleWrapperStyle,
  BeneficiaryPercentageInput,
  BeneficiaryRadioButtonOptionsContainer,
  BeneficiarySplashContainer,
  BeneficiaryStyledRadioLabel,
  BeneficiarySummaryCellStyle,
  BeneficiaryInputFieldWrapper,
  BeneficiaryPaymentSummaryPanel,
  BeneficiarySliderWrapper,
  BeneficiaryStyledRow,
  BeneficiaryButtonContents,
  BeneficiarySliderAndIconStyle,
  BeneficiaryRelationshipDisplayStyle,
  BeneficiaryLoadingLinesWrapper,
  AddBeneficiaryFormWrapperStyle,
} from 'beneficiaries/styles/beneficiary-summary-styles';
import { CreateOrUpdateBeneficiary } from './create-or-update-beneficiary';
import { ProductModule } from 'product-modules/domain/product-module';
import styled from 'styled-components';
import { DeleteBeneficiaryModal } from './delete-beneficiary';
import scaleIcon from '../../assets/icon-16-px-scale.svg';
import { BinIcon } from 'assets/bin-icon';
import { PencilIcon } from 'assets/pencil-icon';
import { LockIconOpen } from 'assets/lock-icon-open';
import { LockIconClosed } from 'assets/lock-icon-closed';
import { usePromiseLazy } from 'shared/hooks/promise';
import { updateApplicationBeneficiaries } from 'applications/actions/update-application-beneficiaries';
import { ErrorAlert } from 'rootstrap/components/error-alert';
import { Policy } from 'policies/domain/policy';
import { updatePolicyBeneficiaries } from 'policies/actions/update-policy-beneficiaries';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import NewSpinner, { AnimationTypes, SpinnerSize } from 'rootstrap/components/spinner/new-spinner';
import { ManagementView, PolicyManagementSceneBaseParams } from 'policy-management/policy-management-scene';
import { EmbedFlowType } from 'App';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { Alert } from 'rootstrap/components/alerts/alert';
import { ApiError } from 'shared/api';
import { MixpanelStepNames, useMixpanelTrack } from 'context/mix-pannel-context';
import { Colors } from 'rootstrap/global-styles/colors';
import {
  GetPolicyIssuingFlowStepFromStepIndex,
  getPolicyIssuingFlowStepOrder,
  IssuingSceneStepKeys,
} from 'policy-issuing/utils';
import { PseudoTooltip } from 'rootstrap/components/tooltip/pseudo-tooltip';
import { scrollToTopOfPage } from 'rootstrap/components/forms/new-fields/utils';

export enum BeneficiarySummaryType {
  Application = 'application',
  Policy = 'policy',
}
interface BeneficiarySummaryProps extends PolicyManagementSceneBaseParams {
  linkedEntity?: Application | Policy;
  policyholder: Policyholder | undefined;
  productModule: ProductModule | undefined;
  setStepProgress: (stepProgress: string) => void;
  priorStepKey?: IssuingSceneStepKeys;
  setIsValid: (isValid: boolean) => void;
  submitButtonRef: React.MutableRefObject<any>;
  setLinkedEntity: (entity: any) => void;
  type: BeneficiarySummaryType;
  beneficiarySummarySceneType: EmbedFlowType;
  setIsLoading: (isLoading: boolean) => void;
  sectionIndices: number[];
}

export const BeneficiarySummary = (props: BeneficiarySummaryProps) => {
  const { siteConfig } = useSiteConfigContext();
  const {
    linkedEntity,
    policyholder,
    productModule,
    setStepProgress,
    priorStepKey,
    submitButtonRef,
    setLinkedEntity,
    type,
    error,
    isLoading: isMounting,
    beneficiarySummarySceneType,
    setIsValid,
    setIsLoading,
    sectionIndices,
  } = props;
  const [beneficiaries, setBeneficiaries] = useState(linkedEntity?.beneficiaries || []);
  const [isDeleteBeneficiaryModalOpen, setIsDeleteBeneficiaryModalOpen] = useState<boolean>(false);
  const [activeBeneficiary, setActiveBeneficiary] = useState<Beneficiary | undefined>(undefined);
  const [isCreateOrEditBeneficiaryOpen, setIsCreateOrEditBeneficiaryOpen] = useState<boolean>(false);
  const [lockedBeneficiaryPercentages, setLockedBeneficiaryPercentages] = useState<{ [k: string]: boolean }>({});
  const [isMobile, setIsMobile] = useState<boolean>(window.matchMedia('(max-width: 767px)').matches);
  const { embedParams } = useEmbedParamsContext();
  const { auth, environment, organizationId } = embedParams;
  const [beneficiariesLengthError, setBeneficiariesLengthError] = useState<string | undefined>(undefined);
  const [shouldDisplayLengthError, setShouldDisplayLengthError] = useState<boolean>(false);
  const [isLoadingRedirect, setIsLoadingRedirect] = useState(false);
  const [beneficiariesError, setBeneficiariesError] = useState<ApiError | undefined>(undefined);
  const maxBeneficiaries = props?.productModule?.productModuleDefinition?.settings?.beneficiaries?.max || 99;
  const { startedTrack, actionStartedTrack } = useMixpanelTrack();

  const stepOrder = getPolicyIssuingFlowStepOrder({
    siteConfig,
    sectionIndices,
  });

  useEffect(() => {
    setBeneficiaries(linkedEntity?.beneficiaries || []);
    updateBeneficiariesValidation({ beneficiaries: linkedEntity?.beneficiaries || [] });
  }, [linkedEntity?.beneficiaries]);

  useLayoutEffect(() => {
    function updateSize() {
      setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const {
    execute,
    isLoading,
    error: updateBeneficiariesError,
  } = usePromiseLazy(async () => {
    if (!linkedEntity) {
      throw new Error('No application or policy found');
    }

    if (type === BeneficiarySummaryType.Application) {
      const updatedApplication = await updateApplicationBeneficiaries({
        applicationId: linkedEntity?.applicationId,
        organizationId,
        beneficiaries,
        auth,
        environment,
      });

      setLinkedEntity(updatedApplication);
      return setStepProgress(
        GetPolicyIssuingFlowStepFromStepIndex({
          step: stepOrder.prePaymentConsent,
          issuingFlowStartingStep: siteConfig?.settings.issuingFlowStartingStep,
          sectionIndices,
        }),
      );
    }

    if (type === BeneficiarySummaryType.Policy && linkedEntity.policyId) {
      const updatedPolicy = await updatePolicyBeneficiaries({
        policyId: linkedEntity?.policyId,
        organizationId,
        beneficiaries,
        auth,
        environment,
      });

      const exitRedirect = siteConfig?.management?.beneficiaries.links.exitRedirect;

      setLinkedEntity(updatedPolicy);
      if (exitRedirect) {
        setIsLoadingRedirect(true);
        return (window.parent.location.href = exitRedirect);
      }
      return setStepProgress(
        GetPolicyIssuingFlowStepFromStepIndex({
          step: stepOrder.prePaymentConsent,
          issuingFlowStartingStep: siteConfig?.settings.issuingFlowStartingStep,
          sectionIndices,
        }),
      );
    }
  }, []);

  useEffect(() => {
    setBeneficiariesError(updateBeneficiariesError);
  }, [updateBeneficiariesError]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  const isInLoadingState = isLoading || isLoadingRedirect;

  useEffect(() => {
    const isProductModuleBeneficiariesEnabled =
      !!productModule?.productModuleDefinition?.settings.beneficiaries.enabled;
    const isEmbedBeneficiariesEnabled =
      !!siteConfig?.beneficiaries?.displayOptionalSections?.displayManageBeneficiaries;

    if (beneficiarySummarySceneType === EmbedFlowType.PolicyIssuing) {
      if (!isProductModuleBeneficiariesEnabled || !isEmbedBeneficiariesEnabled) {
        if (priorStepKey && stepOrder[priorStepKey] > stepOrder.beneficiaries) {
          return setStepProgress(
            GetPolicyIssuingFlowStepFromStepIndex({
              step: stepOrder.beneficiaries - 1,
              issuingFlowStartingStep: siteConfig?.settings.issuingFlowStartingStep,
              sectionIndices,
            }),
          );
        }
        return setStepProgress(
          GetPolicyIssuingFlowStepFromStepIndex({
            step: stepOrder.prePaymentConsent,
            issuingFlowStartingStep: siteConfig?.settings.issuingFlowStartingStep,
            sectionIndices,
          }),
        );
      }
      if (isProductModuleBeneficiariesEnabled || isEmbedBeneficiariesEnabled) {
        if (type === BeneficiarySummaryType.Application) {
          startedTrack({
            stepName: MixpanelStepNames.Beneficiaries,
          });
        } else {
          actionStartedTrack({ action: ManagementView.Beneficiaries });
        }

        scrollToTopOfPage();
      }
    }
  }, [productModule]);

  const updateAllBeneficiaries = (params: { beneficiaries: Beneficiary[] }) => {
    const { beneficiaries } = params;

    const filteredNonLockedBeneficiaries = beneficiaries.filter(
      ({ beneficiaryId }) => !lockedBeneficiaryPercentages[beneficiaryId],
    );

    const total =
      100 -
      beneficiaries
        .filter(({ beneficiaryId }) => lockedBeneficiaryPercentages[beneficiaryId])
        .reduce((total, acc) => (total = total + acc.percentage), 0);

    const percentage = total / filteredNonLockedBeneficiaries.length;
    const percentageModulus = total % filteredNonLockedBeneficiaries.length;

    const zeroIndexNonLockedBeneficiary = filteredNonLockedBeneficiaries[0];

    const updatedBeneficiaries = beneficiaries.map((beneficiary, index) => {
      if (!!filteredNonLockedBeneficiaries.find(({ beneficiaryId }) => beneficiaryId === beneficiary.beneficiaryId)) {
        return new Beneficiary({
          ...beneficiary,
          percentage:
            zeroIndexNonLockedBeneficiary.beneficiaryId === beneficiary.beneficiaryId
              ? Math.floor(percentage) + percentageModulus
              : Math.floor(percentage),
        });
      }
      return beneficiary;
    });

    setBeneficiaries(updatedBeneficiaries.map((b: any) => new Beneficiary(b)));
    updateBeneficiariesValidation({ beneficiaries: updatedBeneficiaries.map((b: any) => new Beneficiary(b)) });
  };

  const updateBeneficiaries = (params: { beneficiaryId: string; percentage: number; beneficiaries: Beneficiary[] }) => {
    const { beneficiaryId, percentage, beneficiaries } = params;

    const index = beneficiaries.findIndex((beneficiaries) => beneficiaries.beneficiaryId === beneficiaryId);
    beneficiaries[index] = new Beneficiary({
      ...beneficiaries[index],
      percentage,
    });

    const updatedBeneficiaries = beneficiaries;

    setBeneficiaries(updatedBeneficiaries.map((b: any) => new Beneficiary(b)));
    updateBeneficiariesValidation({ beneficiaries: updatedBeneficiaries.map((b: any) => new Beneficiary(b)) });
  };

  const updateBeneficiariesValidation = (params: { beneficiaries: Beneficiary[] }) => {
    const beneficiariesLengthValidation = getBeneficiariesLengthValidation({
      beneficiaries: params.beneficiaries,
      productModule,
    });
    setBeneficiariesLengthError(beneficiariesLengthValidation);
    setIsValid(!beneficiariesLengthValidation);
    setBeneficiariesError(undefined);
  };

  return (
    <>
      {isDeleteBeneficiaryModalOpen && (
        <DeleteBeneficiaryModal
          onSuccess={() => {
            setShouldDisplayLengthError(true);
          }}
          setBeneficiaries={(beneficiaries) => {
            setBeneficiaries(beneficiaries);
            setActiveBeneficiary(undefined);
            updateBeneficiariesValidation({ beneficiaries });
          }}
          closeModal={() => setIsDeleteBeneficiaryModalOpen(false)}
          beneficiaries={beneficiaries}
          beneficiary={activeBeneficiary}
        />
      )}
      {isCreateOrEditBeneficiaryOpen && (
        <CreateOrUpdateBeneficiary
          setBeneficiaries={(beneficiaries) => {
            setBeneficiaries(beneficiaries);
            setActiveBeneficiary(undefined);
            updateBeneficiariesValidation({ beneficiaries });
          }}
          beneficiaries={beneficiaries}
          toggle={() => setIsCreateOrEditBeneficiaryOpen(!isCreateOrEditBeneficiaryOpen)}
          productModule={productModule}
          beneficiary={activeBeneficiary}
        />
      )}
      {isMounting && (
        <StyledLoadingInputsWrapper>
          <WrappedLoadingInputs count={3} />
        </StyledLoadingInputsWrapper>
      )}
      {!isMounting && beneficiaries.length > 0 && (
        <BeneficiarySplashContainer siteConfig={siteConfig}>
          <BeneficiaryCardTitleWrapperStyle siteConfig={siteConfig}>
            <BeneficiaryCardTitleStyle id='beneficiary-summary-share-allocation-title'>
              Beneficiary share allocation
            </BeneficiaryCardTitleStyle>
            <PercentageInfoList siteConfig={siteConfig}>
              <ul>
                <li>
                  <span>Adjust a percentage by dragging the slider bar.</span>
                </li>
                <li>
                  <span>Click the lock icon to lock or unlock a percentage for a specific beneficiary.</span>
                </li>
                <li>
                  <span>
                    Use the <strong>Allocate evenly</strong> button to distribute shares equally among all unlocked
                    beneficiaries.
                  </span>
                </li>
              </ul>
            </PercentageInfoList>
            <BeneficiaryAllocateButtonWrapperStyle>
              {!isMounting && (
                <SuccessButton
                  fullWidth
                  id='allocate-evenly-button'
                  siteConfig={siteConfig}
                  disabled={isInLoadingState}
                  onClick={() => updateAllBeneficiaries({ beneficiaries })}
                >
                  <img height={20} src={scaleIcon} alt='' />
                  Allocate evenly
                </SuccessButton>
              )}
              {isMounting && <NewSpinner animation={AnimationTypes.Border} size={SpinnerSize.sm} color='FFFFFF' />}
            </BeneficiaryAllocateButtonWrapperStyle>
          </BeneficiaryCardTitleWrapperStyle>
          {(!linkedEntity || isInLoadingState) && (
            <BeneficiaryLoadingLinesWrapper>
              <LoadingLines />
            </BeneficiaryLoadingLinesWrapper>
          )}
          {linkedEntity && !isInLoadingState && (
            <div>
              <BeneficiaryPaymentSummaryPanel siteConfig={siteConfig} isMobile={isMobile}>
                {beneficiaries?.map((beneficiary, index) => {
                  const isBeneficiaryLocked = !!lockedBeneficiaryPercentages[beneficiary.beneficiaryId];
                  const beneficiaryFullName = beneficiary.policyholderId
                    ? policyholder?.fullName()
                    : beneficiary.fullname();

                  return (
                    <BeneficiaryStyledRow isLeftComponent={!(index % 2) || isMobile} siteConfig={siteConfig}>
                      <BeneficiarySummaryCellStyle
                        siteConfig={siteConfig}
                        hideBorderRight={true}
                        hideBorderBottom={true}
                        isLeftComponent={!(index % 2) || isMobile}
                      >
                        <div>
                          <BeneficiaryFullNameStyle id={`beneficiary-summary-beneficiary-card-${index}-name`}>
                            {beneficiaryFullName}
                          </BeneficiaryFullNameStyle>
                          <BeneficiaryInputFieldWrapper siteConfig={siteConfig}>
                            <BeneficiaryPercentageInput
                              id={`beneficiary-percentage-${index}`}
                              siteConfig={siteConfig}
                              disabled={isBeneficiaryLocked}
                              type='number'
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                updateBeneficiaries({
                                  beneficiaryId: beneficiary.beneficiaryId,
                                  percentage: Number(e.target.value),
                                  beneficiaries,
                                });
                              }}
                              value={Number(beneficiary.percentage.toFixed(1)) || 0}
                            />
                            <span>%</span>
                          </BeneficiaryInputFieldWrapper>
                        </div>
                        <BeneficiaryRelationshipDisplayStyle siteConfig={siteConfig}>
                          {beneficiary.prettyRelationship()}
                        </BeneficiaryRelationshipDisplayStyle>
                        <BeneficiarySliderAndIconStyle>
                          <BeneficiarySliderWrapper isLeftComponent={!(index % 2)}>
                            <StyledSlider
                              borderColor={getColor({ siteConfig, color: 'border' })}
                              primaryColor={getColor({ siteConfig, color: 'primary' })}
                              sliderRailColor={getColor({ siteConfig, color: 'border' })}
                              value={beneficiary.percentage}
                              disabled={isBeneficiaryLocked}
                              step={1}
                              onChange={(percentage: any) =>
                                updateBeneficiaries({
                                  beneficiaryId: beneficiary.beneficiaryId,
                                  percentage,
                                  beneficiaries,
                                })
                              }
                              handleRender={(handleProps) => {
                                const clonedProps = { ...handleProps.props };

                                return (
                                  <>
                                    <div {...clonedProps}>
                                      <PseudoTooltip
                                        borderColor={getColor({ siteConfig, color: 'border' })}
                                        content={`${beneficiary.percentage}%`}
                                        alignment={getSliderTooltipAlignment({
                                          value: beneficiary.percentage,
                                          min: 0,
                                          max: 100,
                                        })}
                                      />
                                    </div>
                                  </>
                                );
                              }}
                            />
                          </BeneficiarySliderWrapper>
                          {!isBeneficiaryLocked && (
                            <LockIconOpen
                              fill={getColor({ siteConfig, color: 'disabled' })}
                              style={{ marginRight: 6, marginBottom: 2 }}
                              onClick={() => {
                                setLockedBeneficiaryPercentages({
                                  ...lockedBeneficiaryPercentages,
                                  [beneficiary.beneficiaryId]: true,
                                });
                              }}
                            />
                          )}
                          {isBeneficiaryLocked && (
                            <LockIconClosed
                              fill={getColor({ siteConfig, color: 'primary' })}
                              style={{ marginRight: 6, marginBottom: 2 }}
                              onClick={() => {
                                setLockedBeneficiaryPercentages({
                                  ...lockedBeneficiaryPercentages,
                                  [beneficiary.beneficiaryId]: false,
                                });
                              }}
                            />
                          )}
                        </BeneficiarySliderAndIconStyle>
                        <RadioButtonWrapper>
                          <BeneficiaryRadioButtonOptionsContainer disabled={false}>
                            <DeleteBeneficiaryButton
                              index={index}
                              isDisabled={!!beneficiary.policyholderId}
                              onClick={() => {
                                setActiveBeneficiary(beneficiary);
                                setIsDeleteBeneficiaryModalOpen(true);
                              }}
                            />
                            <EditBeneficiaryButton
                              isDisabled={!!beneficiary.policyholderId}
                              onClick={() => {
                                setActiveBeneficiary(beneficiary);
                                setIsCreateOrEditBeneficiaryOpen(true);
                              }}
                            />
                          </BeneficiaryRadioButtonOptionsContainer>
                        </RadioButtonWrapper>
                      </BeneficiarySummaryCellStyle>
                    </BeneficiaryStyledRow>
                  );
                })}
              </BeneficiaryPaymentSummaryPanel>
            </div>
          )}
        </BeneficiarySplashContainer>
      )}
      <AddBeneficiaryFormWrapperStyle>
        <SuccessButton
          id='beneficiary-summary-add-beneficiary-button'
          outline
          siteConfig={siteConfig}
          onClick={() => {
            setActiveBeneficiary(undefined);
            setIsCreateOrEditBeneficiaryOpen(!isCreateOrEditBeneficiaryOpen);
          }}
          fullWidth={true}
          disabled={isInLoadingState || beneficiaries.length >= maxBeneficiaries}
        >
          <span style={{ paddingRight: 10 }}>+</span> Add beneficiary
        </SuccessButton>
      </AddBeneficiaryFormWrapperStyle>
      <button
        style={{ display: 'none' }}
        onClick={async () => {
          setShouldDisplayLengthError(true);
          setBeneficiariesLengthError('');
          const beneficiariesLengthValidation = getBeneficiariesLengthValidation({ beneficiaries, productModule });
          setIsValid(!beneficiariesLengthValidation);

          if (beneficiariesLengthValidation) {
            return setBeneficiariesLengthError(beneficiariesLengthValidation);
          }

          await execute();
        }}
        ref={submitButtonRef}
        type='submit'
      />
      {(error || beneficiariesError || beneficiariesLengthError) && (
        <FormWrapperStyle style={{ marginTop: Spacing.sm, marginBottom: -40 }}>
          {beneficiariesLengthError && shouldDisplayLengthError && (
            <Alert id='beneficiary-length-error' variant='danger' body={beneficiariesLengthError} />
          )}
          <ErrorAlert error={error || beneficiariesError} />
        </FormWrapperStyle>
      )}
    </>
  );
};

const EditBeneficiaryButton = (params: { onClick: () => void; isDisabled: boolean }) => {
  const { siteConfig } = useSiteConfigContext();
  const { onClick, isDisabled } = params;

  return (
    <BeneficiaryStyledRadioLabel
      borderColor={getColor({ siteConfig, color: 'border' })}
      siteConfig={siteConfig}
      isDisabled={isDisabled}
      isSelected={true}
      color={getColor({ siteConfig: siteConfig, color: isDisabled ? 'disabled' : 'primary' })}
      isFirstItem={false}
      isLastItem={true}
      disableActiveElement={true}
      isActive={true}
    >
      <Input
        disabled={isDisabled}
        onClick={onClick}
        type='radio'
        value={'details'}
        style={{ opacity: 1, cursor: 'pointer' }}
      />
      <BeneficiaryButtonContents>
        <PencilIcon fill={'white'} style={{ marginRight: 6, marginBottom: 2 }} />
        <span>{'Details'}</span>
      </BeneficiaryButtonContents>
    </BeneficiaryStyledRadioLabel>
  );
};

const StyledLoadingInputsWrapper = styled(FormWrapperStyle)`
  margin-top: ${globalStyles.spacing.md};
`;

const DeleteBeneficiaryButton = (params: { onClick: () => void; isDisabled: boolean; index: number }) => {
  const { siteConfig } = useSiteConfigContext();
  const { onClick, isDisabled, index } = params;
  return (
    <BeneficiaryStyledRadioLabel
      isDisabled={isDisabled}
      siteConfig={siteConfig}
      borderColor={getColor({ siteConfig, color: 'border' })}
      isSelected={true}
      color={globalStyles.colors.White}
      isFirstItem={true}
      isLastItem={false}
      disableActiveElement={true}
      isActive={true}
      id={`delete-beneficiary-${index}`}
    >
      <Input
        disabled={isDisabled}
        onClick={onClick}
        type='radio'
        value={'delete'}
        style={{ opacity: 1, cursor: 'pointer' }}
      />
      <BeneficiaryButtonContents>
        <BinIcon
          fill={getColor({ siteConfig, color: isDisabled ? 'disabled' : 'primary' })}
          style={{ marginRight: 6, marginBottom: 2 }}
        />
        <span>{'Delete'}</span>
      </BeneficiaryButtonContents>
    </BeneficiaryStyledRadioLabel>
  );
};

const getBeneficiariesLengthValidation = (params: {
  beneficiaries: Beneficiary[];
  productModule: ProductModule | undefined;
}) => {
  const { beneficiaries, productModule } = params;
  const minimumNumberOfBeneficiaries = productModule?.productModuleDefinition?.settings.beneficiaries.min || 0;
  const minimumNumberOfBeneficiariesText = minimumNumberOfBeneficiaries === 1 ? 'beneficiary.' : 'beneficiaries.';

  if (beneficiaries.length < minimumNumberOfBeneficiaries) {
    return `Please complete at least ${minimumNumberOfBeneficiaries} ${minimumNumberOfBeneficiariesText}`;
  }

  const maximumNumberOfBeneficiaries = productModule?.productModuleDefinition?.settings.beneficiaries.max || 0;
  const maximumNumberOfBeneficiariesText = minimumNumberOfBeneficiaries === 1 ? 'beneficiary.' : 'beneficiaries.';

  if (beneficiaries.length > maximumNumberOfBeneficiaries) {
    return `Please complete no more than ${maximumNumberOfBeneficiaries} ${maximumNumberOfBeneficiariesText}`;
  }

  const totalPercentage = params.beneficiaries.reduce((acc, beneficiary) => acc + beneficiary.percentage || 0, 0);

  if (totalPercentage !== 100 && beneficiaries.length > 0) {
    return `The percentage allocation does not add up to 100%. Please adjust the percentage allocations`;
  }
};

const BeneficiaryFullNameStyle = styled.div`
  flex: 1;
  align-items: center;
  font-size: ${globalStyles.fontSize.body} !important;
`;

const PercentageInfoList = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  color: ${Colors.Body};
  font-family: ${({ siteConfig }) => getFontFamily({ siteConfig, fontFamily: 'title' })};
  font-size: ${({ siteConfig }) => getFontSize({ siteConfig, fontSize: 'body' })};

  margin-left: 20px;
  margin-bottom: 30px;

  ul {
    padding-left: 0;
    line-height: 18px;
    margin-bottom: 0;
  }
`;
