import { CollectionModuleDefinitionVersion } from 'collection-modules/domain/collection-module';
import { Policy } from 'policies/domain/policy';
import { Policyholder } from 'policyholder/domain/policyholder';
import React, { useEffect, useRef, useState } from 'react';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { useForm } from 'react-hook-form';
import { usePromise, usePromiseLazy } from 'shared/hooks/promise';
import { renderCreatePaymentMethod } from 'collection-modules/actions/render-create-payment-method';
import { Environment } from 'models/environment';
import { JSONObject } from 'shared/utils';
import {
  collectionModuleHTMLSanitization,
  collectionModuleHTMLScript,
} from 'collection-modules/views/utils/collection-module-html-sanitization';
import { useSetIframeHeight } from 'policy-issuing/utils';
import { ErrorAlert } from 'rootstrap/components/error-alert';
import { StyledLoadingLinesWrapper } from 'policy-issuing/payment/views/collections-module-payment-method';
import { LoadingLines } from 'rootstrap/components-old/loaders/loading-lines';
import { createCollectionModulePaymentMethod } from 'payment-methods/actions/create-collection-module-payment-method';
import { getCollectionModuleVersionFromQueryParam } from 'policy-issuing/payment/utils/get-collection-module-version-from-query-param';
import { useQueryParams } from 'shared/hooks/url';
import { useSiteConfigContext } from 'style-context';
import { updatePolicy } from 'policies/actions/update-policy';
import { StaticFormWithTitleRowStyle } from 'rootstrap/components/forms/styles/static-form-with-title-row-style';
import { StyledBeneficiaryCol } from 'beneficiaries/styles/beneficiary-details.styles';
import { BillingDaySelectField } from 'rootstrap/components/forms/new-fields/extended-components/billing-day-select-field';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { ActiveElement } from 'rootstrap/components/forms/new-fields/utils';
import { InputFieldDisplayProperties } from 'rootstrap/components/forms/new-fields/input-field';
import { CollectionModulePaymentMethod, PaymentMethodAll } from 'payment-methods/domain/payment-method';
import { LongButtonSuccess } from 'rootstrap/components/button/styles';
import NewSpinner, { AnimationTypes, SpinnerSize } from 'rootstrap/components/spinner/new-spinner';
import { useMixpanelTrack } from 'context/mix-pannel-context';
import { ManagementView } from 'policy-management/policy-management-scene';

interface CollectionModulePaymentMethodDetailsFormData {
  externalPaymentMethodType?: { label: string; value: string };
  externalReference?: string;
  billingDay?: number;
}

interface Props {
  policy: Policy;
  policyholder: Policyholder;
  paymentMethod: CollectionModulePaymentMethod | undefined;
  collectionModuleKey: string;
  collectionModuleDefinitionVersion: CollectionModuleDefinitionVersion | undefined;
  activeElement: ActiveElement;
  setPolicy: (policy: Policy) => void;
  setActiveElement: (params: ActiveElement) => void;
  setIsUpdatingOrCreatingPaymentMethod: (v: boolean) => void;
  setPaymentMethod: (p: PaymentMethodAll) => void;
}

export enum CollectionModulePaymentInputs {
  BillingDay = 'billingDay',
}

export const NewCollectionsModuleDetails = (props: Props) => {
  const {
    collectionModuleKey,
    policyholder,
    policy,
    collectionModuleDefinitionVersion,
    setPolicy,
    setActiveElement,
    setPaymentMethod,
    setIsUpdatingOrCreatingPaymentMethod,
  } = props;
  const { embedParams } = useEmbedParamsContext();
  const { siteConfig } = useSiteConfigContext();
  const { queryParams } = useQueryParams<{ draft_collection_module: string }>();
  const [isIframeLoading, setIframeLoading] = useState<boolean>(false);
  const [iframeError, setIframeError] = useState<Error | undefined>();
  const { actionCompletedTrack } = useMixpanelTrack();

  const { auth, organizationId, environment } = embedParams;
  const { Draft, Live } = CollectionModuleDefinitionVersion;

  const onSubmitRef = useRef<any>();

  const isTouched = false;

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isIframeValid, setIsIframeValid] = useState<boolean>(true);

  const existingCollectionModulePaymentMethodDetails = useForm<CollectionModulePaymentMethodDetailsFormData>({
    mode: 'onChange',
  });
  const billingDay = existingCollectionModulePaymentMethodDetails.watch(CollectionModulePaymentInputs.BillingDay);
  const { isDirty, isValid } = existingCollectionModulePaymentMethodDetails.formState;

  const canEditPaymentMethod = siteConfig?.management?.payment.displayOptionalSections.editPaymentMethod;
  const canEditBillingDay = siteConfig?.management?.payment.displayOptionalSections.editBillingDay;

  const { result, isLoading, error } = usePromise(async () => {
    const versionIsDraft = getCollectionModuleVersionFromQueryParam(queryParams.draft_collection_module);

    return renderCreatePaymentMethod({
      collectionModuleKey,
      environment,
      auth,
      organizationId,
      policyId: policy.policyId,
      policyholderId: policyholder.policyholderId,
      version: environment === Environment.Sandbox ? versionIsDraft : undefined,
    });
  }, [collectionModuleDefinitionVersion]);

  const {
    execute: createPaymentMethod,
    isLoading: isLoadingCreatePaymentMethod,
    error: errorCreatePaymentMethod,
  } = usePromiseLazy(async (params: { data: JSONObject; billingDay: number | undefined }) => {
    const versionIsDraft = getCollectionModuleVersionFromQueryParam(queryParams.draft_collection_module);

    if (canEditPaymentMethod) {
      const updatedPaymentMethod = await createCollectionModulePaymentMethod({
        organizationId,
        environment,
        collectionModuleKey: collectionModuleKey || '',
        data: params.data,
        policyholderId: policyholder.policyholderId,
        policyId: policy.policyId,
        auth,
        version: environment === Environment.Sandbox ? versionIsDraft : undefined,
      });
      setPaymentMethod(updatedPaymentMethod);
    }

    if (billingDay !== policy.billingDay && canEditBillingDay) {
      const updatedPolicy = await updatePolicy({
        environment,
        organizationId,
        auth,
        data: { billingDay },
        policyId: policy.policyId,
      });

      setPolicy(updatedPolicy);
    }

    actionCompletedTrack({ action: ManagementView.PaymentDetails });
    setIsUpdatingOrCreatingPaymentMethod(false);
  }, []);

  // Assuming your iframe has an id of 'your-iframe-id'
  useEffect(() => {
    (window as any).setIsLoading = setIframeLoading;
    (window as any).setIsValid = setIsIframeValid;
    (window as any).setError = setIframeError;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (window as any).completeCreatePaymentMethod = (data: any) =>
      createPaymentMethod({
        data,
        billingDay,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingDay]);

  const modifiedHtmlContent = collectionModuleHTMLSanitization({
    htmlContent: result?.html || '',
    script: collectionModuleHTMLScript,
  });

  useSetIframeHeight({ iframeId: 'collections-module-iframe', isLoading, modifiedHtmlContent });

  const submitIframeForm = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      (iframe.contentWindow as any).submitCreatePaymentMethod();
    }
  };

  return (
    <div>
      <ErrorAlert error={errorCreatePaymentMethod || error || iframeError} />
      {(isLoading || isLoadingCreatePaymentMethod) && (
        <StyledLoadingLinesWrapper>
          <LoadingLines lines={[100, 80, 90, 70]} />
        </StyledLoadingLinesWrapper>
      )}
      {!isLoading && !isLoadingCreatePaymentMethod && (
        <div>
          <iframe
            ref={iframeRef} // Attach the ref to the iframe
            id='collections-module-iframe'
            title='collections module iframe'
            srcDoc={modifiedHtmlContent}
            width='100%'
            height='100%'
            frameBorder='0'
          />
          <button
            id='create-collections-module-hidden-submit-button'
            style={{ display: 'none' }}
            ref={onSubmitRef}
            onClick={submitIframeForm}
          />
        </div>
      )}
      {canEditBillingDay && (
        <StaticFormWithTitleRowStyle siteConfig={siteConfig}>
          <StyledBeneficiaryCol sm={12}>
            <BillingDaySelectField
              clearable={true}
              isTouched={isTouched}
              disableScrollToElement={true}
              name={CollectionModulePaymentInputs.BillingDay}
              validators={[
                {
                  validation: {
                    type: ValidationTypes.REQUIRED,
                  },
                },
              ]}
              label='Debit day'
              form={existingCollectionModulePaymentMethodDetails}
              defaultValue={policy.billingDay}
              prefillValue={CollectionModulePaymentInputs.BillingDay}
              placeholder={'Debit day'}
              disableActiveElement={true}
              hideDivider={true}
              displayProperties={
                {
                  activeElement: {
                    elementId: '',
                  },
                  setActiveElement,
                  nextComponentName: '',
                } as InputFieldDisplayProperties
              }
            />
          </StyledBeneficiaryCol>
          <LongButtonSuccess
            id='manage-payment-methods-submit-button'
            siteConfig={siteConfig}
            onClick={() => onSubmitRef.current.click()}
            disabled={isLoading || isIframeLoading || !isValid || !isIframeValid}
          >
            {(isLoading || isIframeLoading) && (
              <span style={{ marginRight: '10px' }}>
                <NewSpinner animation={AnimationTypes.Border} size={SpinnerSize.sm} color='FFFFFF' />
              </span>
            )}
            Save
          </LongButtonSuccess>
        </StaticFormWithTitleRowStyle>
      )}
    </div>
  );
};
